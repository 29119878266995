import React, { FC } from 'react';
import dayjs from 'dayjs';

import Typography from '@mui/material/Typography';
import Section from 'components/Section';

import { OrderHistoryentry } from 'features';
import { DATE_FORMAT } from 'const';

type Props = {
  entry: OrderHistoryentry;
};

const OrderHistoryEntry: FC<Props> = ({ entry }) => (
  <Section title={dayjs(entry.time).format(DATE_FORMAT)} sx={{ mb: 1 }}>
    <Typography variant="body1">{entry.user}</Typography>
    <Typography variant="body2">{entry.text}</Typography>
  </Section>
);

export default OrderHistoryEntry;
