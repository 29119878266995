import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import lt from './lt/translation.json';
import en from './en/translation.json';

// TODO: @Incomplete: add typings https://react.i18next.com/latest/typescript

i18n.use(initReactI18next).init({
  resources: { lt, en },
  lng: 'lt',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
