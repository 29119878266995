import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Client, useClients, useCurrentUser } from 'features';
import { useIsSuper } from 'lib';
import { useSearchParams } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

type SelectedClientType = {
  client: Client | null;
  setClient: (client: Client | null) => void;
};

const SelectedClientContext = createContext<SelectedClientType>({
  client: null,
  setClient: () => {},
});

export const useSelectedClient = () => useContext(SelectedClientContext);

const SelectedClient: FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const session = sessionStorage.getItem('client_id');
  const query = useClients();
  const currentUser = useCurrentUser();
  const isSuper = useIsSuper();

  const queryParamsClient = query.data?.find(
    item => item.id === (session ?? searchParams.get('client_id'))
  );

  const [client, setClient] = useState<Client | null>(
    (isSuper ? queryParamsClient ?? query.data![0] : currentUser?.clients[0]) ??
      null
  );

  const handleSetClient = useCallback(
    (client: Client | null) => {
      setClient(client);
      setSearchParams({}, { replace: true });

      if (client) {
        sessionStorage.setItem('client_id', client.id);
      } else {
        sessionStorage.removeItem('client_id');
      }
    },
    [setSearchParams]
  );

  return (
    <SelectedClientContext.Provider
      value={{ client, setClient: handleSetClient }}>
      {children}
    </SelectedClientContext.Provider>
  );
};

export default SelectedClient;
