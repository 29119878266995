import { QueryFunctionContext, useQuery } from 'react-query';

import {
  ClientParams,
  MonthlySummary,
  PeriodType,
  Usage,
  UsageParams,
} from 'features';
import { api, formatDate, useClientParams } from 'lib';

type UseUsageProps = {
  from: Date;
  to: Date;
  periodType: PeriodType;
};

export const usageKeys = {
  all: [{ scope: 'usage' }] as const,

  lists: () => [{ ...usageKeys.all[0], entity: 'list' }] as const,
  list: (params: UsageParams) => [{ ...usageKeys.lists()[0], params }] as const,

  monthlySummaries: () => [{ ...usageKeys.all[0], entity: 'monthly' }] as const,
  monthlySummary: (params: ClientParams) =>
    [{ ...usageKeys.monthlySummaries()[0], params }] as const,
};

type UsageListContext = QueryFunctionContext<
  ReturnType<typeof usageKeys['list']>
>;

type MonthlySummaryContext = QueryFunctionContext<
  ReturnType<typeof usageKeys['monthlySummary']>
>;

const fetchUsage = ({ queryKey: [{ params }] }: UsageListContext) =>
  api().get<Usage[]>('/usage', params);

const fetchMonthlySummary = ({
  queryKey: [{ params }],
}: MonthlySummaryContext) =>
  api().get<MonthlySummary>('/usage/dashboard/monthly', params);

export const useUsage = (props: UseUsageProps) => {
  const { from, to, periodType } = props;
  const clientParams = useClientParams();

  const params = {
    ...clientParams,
    from: formatDate(from),
    to: formatDate(to),
    period_type: periodType,
  };

  return useQuery(usageKeys.list(params), fetchUsage);
};

export const useMonthlySummary = () =>
  useQuery(usageKeys.monthlySummary(useClientParams()), fetchMonthlySummary);
