import React, { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';

const ProductListSkeleton: FC = () => (
  <>
    <Skeleton variant="rectangular" height={70} sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" height={70} sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" height={70} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={70} />
  </>
);

export default ProductListSkeleton;
