import React, { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Breadcrumbs from 'components/Breadcrumbs';

const UserEditSkeleton: FC = () => {
  return (
    <>
      <Breadcrumbs
        breadcrumbs={[{ label: 'USERS', href: '/users' }, { skeleton: true }]}
      />

      <Skeleton variant="rectangular" height={50} sx={{ mb: 1 }} />
      <Skeleton variant="rectangular" height={332} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={68} />
    </>
  );
};

export default UserEditSkeleton;
