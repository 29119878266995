import React, { FC } from 'react';

import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import { InvoiceStatus as InvoiceStatusType } from 'features';
import { useTranslation } from 'react-i18next';

type Props = {
  status: InvoiceStatusType;
};

const InvoiceStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation('InvoiceStatus');

  switch (status) {
    case 'pending':
      return (
        <Tooltip title={t('PENDING')}>
          <PendingIcon />
        </Tooltip>
      );

    case 'paid':
      return (
        <Tooltip title={t('PAID')}>
          <CheckCircleIcon color="success" />
        </Tooltip>
      );

    case 'overdue':
      return (
        <Tooltip title={t('OVERDUE')}>
          <ErrorIcon color="error" />
        </Tooltip>
      );
  }
};

export default InvoiceStatus;
