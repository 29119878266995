import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, TextField } from '@brunas/dashboard';
import * as yup from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { useUpdatePassword } from 'features';

type FormValues = {
  currentPassword: string;
  password: string;
  passwordRepeat: string;
};

const initial = {
  currentPassword: '',
  password: '',
  passwordRepeat: '',
};

const Password: FC = () => {
  const { t } = useTranslation('Password');
  const mutation = useUpdatePassword();

  const schema = useMemo(
    () =>
      yup.object({
        password: yup.string().required(t('ENTER_NEW_PASSWORD')),
        // TODO: @Incomplete need to validate that this same as password
        passwordRepeat: yup.string().required(t('CONFIRM_NEW_PASSOWRD')),
      }),
    [t]
  );

  return (
    <Form<FormValues>
      initial={initial}
      onSubmit={mutation.mutate}
      schema={schema}
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DialogContent sx={{ flexGrow: 1 }}>
        <Stack spacing={2}>
          {/* <TextField
            type="password"
            name="currentPassword"
            label={t('CURRENT_PASSWORD')}
          /> */}

          <TextField
            type="password"
            name="password"
            label={t('NEW_PASSWORD')}
          />

          <TextField
            type="password"
            name="passwordRepeat"
            label={t('REPEAT_PASSWORD')}
          />
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <LoadingButton
          type="submit"
          startIcon={<SaveIcon />}
          loading={mutation.isLoading}
          variant="contained">
          {t('CHANGE')}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
};

export default Password;
