import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { useSelectedClient } from 'components/SelectedClient';

type Props = {};

const TransportManagementButton: FC<Props> = () => {
  const { t } = useTranslation('MainToolbar');
  const link = useSelectedClient().client?.domains[0];

  if (!link) return null;

  return (
    <Tooltip title={t('TRANSPORT_MANAGEMENT')}>
      <IconButton color="inherit" href={`https://${link}`}>
        <LocalShippingIcon />
      </IconButton>
    </Tooltip>
  );
};

export default TransportManagementButton;
