import { FC, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  action?: ReactNode;
  sx?: SxProps<Theme>;
};

const Section: FC<Props> = ({ title, subTitle, children, action, sx }) => (
  <Paper sx={[{ p: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}>
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1 }}>
        {title && (
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
        )}

        {subTitle && <Typography variant="body2">{subTitle}</Typography>}
      </Box>

      <Box>{action}</Box>
    </Box>

    {children}
  </Paper>
);

export default Section;
