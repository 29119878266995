import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';

type Props = {
  label?: string;
  href?: string | null;
  translate?: boolean;
  skeleton?: boolean;
};

export type BreadcrumbProps = Props;

const Breadcrumb: FC<Props> = ({ label, href, translate = true, skeleton }) => {
  const { t } = useTranslation('Breadcrumb');

  if (skeleton) return <Skeleton width={100} />;

  const labelText = translate && label ? t(label) : label;

  return href ? (
    <Link href={href}>{labelText}</Link>
  ) : (
    <Typography>{labelText}</Typography>
  );
};

export default Breadcrumb;
