import { FC } from 'react';
import dayjs from 'dayjs';

import TableRow from '@mui/material/TableRow';
import TableCell from 'components/TableCell';
import InvoiceStatus from 'components/InvoiceStatus';
import InvoicePdfLink from 'components/InvoicePdfLink';

import { Invoice } from 'features';
import { DATE_FORMAT } from 'const';

type Props = {
  invoice: Invoice;
};

const InvoicesTableRow: FC<Props> = ({ invoice }) => (
  <TableRow>
    <TableCell>
      <InvoiceStatus status={invoice.status} />
    </TableCell>

    <TableCell>{dayjs(invoice.time).format(DATE_FORMAT)}</TableCell>

    <TableCell>
      <InvoicePdfLink invoice={invoice} />
    </TableCell>

    <TableCell>{invoice.amount}</TableCell>
  </TableRow>
);

export default InvoicesTableRow;
