import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import UsersTableRow from 'components/UsersTableRow';

import { useDeleteUser, User } from 'features';

type Props = {
  users: User[];
};

const UsersTable: FC<Props> = ({ users }) => {
  const { t } = useTranslation('UsersTable');
  const { mutate } = useDeleteUser();

  return (
    <Table header={[t('NAME'), t('EMAIL'), t('ROLES'), null]}>
      {users.map(user => (
        <UsersTableRow key={user.id} user={user} onDelete={mutate} />
      ))}
    </Table>
  );
};

export default UsersTable;
