import { FC } from 'react';

import Breadcrumbs from 'components/Breadcrumbs';
import Section from 'components/Section';
import TableSkeleton from 'components/TableSkeleton';
import InvoicesTable from 'components/InvoicesTable';

import { useInvoices } from 'features';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';

const Invoices: FC = () => {
  const query = useInvoices();
  const { t } = useTranslation('Invoices');

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[{ label: 'BILLING' }, { label: 'INVOICES' }]}
      />

      {query.data ? (
        query.data.length > 0 ? (
          <Section>
            <InvoicesTable invoices={query.data} />
          </Section>
        ) : (
          <Alert severity="info" variant="filled">
            {t('NO_INVOICES')}
          </Alert>
        )
      ) : (
        <TableSkeleton cols={4} />
      )}
    </>
  );
};

export default Invoices;
