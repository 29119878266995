import { FC } from 'react';
import { FabDialog } from '@brunas/dashboard';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Breadcrumbs from 'components/Breadcrumbs';
import TableSkeleton from 'components/TableSkeleton';
import Section from 'components/Section';
import UsersTable from 'components/UsersTable';
import AddUsersDialog from 'components/AddUsersDialog';

import { useUsers } from 'features';

const Users: FC = () => {
  const query = useUsers();

  return (
    <>
      <Breadcrumbs breadcrumbs={[{ label: 'USERS' }]} />

      <Section>
        {query.data ? (
          <UsersTable users={query.data} />
        ) : (
          <TableSkeleton cols={4} />
        )}

        <FabDialog icon={<PersonAddIcon />} fullWidth maxWidth="sm">
          <AddUsersDialog />
        </FabDialog>
      </Section>
    </>
  );
};

export default Users;
