import { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';
import TableSkeleton from 'components/TableSkeleton';

const BillingUsageSkeleton: FC = () => {
  return (
    <>
      <TableSkeleton cols={4} />
      <Skeleton variant="rectangular" height={420} sx={{ mb: 2 }} />
    </>
  );
};

export default BillingUsageSkeleton;
