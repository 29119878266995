import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Section from 'components/Section';
import BillingUsageTableRow from 'components/BillingUsageTableRow';

import { Usage } from 'features';

type Props = {
  usage: Usage[];
};

const BillingUsageTable: FC<Props> = ({ usage }) => {
  const { t } = useTranslation('BillingUsageTable');

  return (
    <Section sx={{ mb: 1 }}>
      <Typography sx={{ textAlign: 'right' }}>
        <Link href="#">{t('DOWNLOAD_XLXS')}</Link>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: 'center' }}>{t('DATE')}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{t('TELEMETRY')}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              {t('DRIVER_APP')}
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              {t('ACCOUNTING')}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {usage.map((item, i) => (
            <BillingUsageTableRow key={i} usage={item} />
          ))}
        </TableBody>
      </Table>
    </Section>
  );
};

export default BillingUsageTable;
