import { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';

const BillingSettingsSkeleton: FC = () => (
  <>
    <Skeleton variant="rectangular" height={424} sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={68} />
  </>
);

export default BillingSettingsSkeleton;
