import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Section from 'components/Section';
import TableSkeleton from 'components/TableSkeleton';
import OrdersTable from 'components/OrdersTable';
import Breadcrumbs from 'components/Breadcrumbs';

import { useOrders } from 'features';
import Alert from '@mui/material/Alert';

type Props = {};

const Orders: FC<Props> = () => {
  const { data } = useOrders();
  const { t } = useTranslation('Orders');

  return (
    <>
      <Breadcrumbs breadcrumbs={[{ label: 'ORDERS' }]} />

      {data ? (
        data.length > 0 ? (
          <Section>
            <OrdersTable orders={data} />
          </Section>
        ) : (
          <Alert severity="info" variant="filled">
            {t('NO_ORDERS')}
          </Alert>
        )
      ) : (
        <TableSkeleton cols={5} />
      )}
    </>
  );
};

export default Orders;
