import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, SimpleChipInput, TextField } from '@brunas/dashboard';
import * as yup from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Breadcrumbs from 'components/Breadcrumbs';
import Section from 'components/Section';
import ActionBox from 'components/ActionBox';
import BillingSettingsSkeleton from 'components/BillingSettingsSkeleton';

import { useRequisites, useUpdateRequisites, Requisites } from 'features';

const schema = yup.object({
  title: yup.string(),
  companyCode: yup.string(),
  companyVatCode: yup.string(),
  address: yup.string(),
  city: yup.string(),
  country: yup.string(),
  invoiceEmailList: yup.array().of(yup.string().email()),
});

const Settings: FC = () => {
  const { t } = useTranslation('Settings');
  const query = useRequisites();
  const mutation = useUpdateRequisites();

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[{ label: 'BILLING' }, { label: 'SETTINGS' }]}
      />

      {query.data ? (
        <Form<Requisites>
          schema={schema}
          initial={query.data}
          onSubmit={mutation.mutate}>
          <Section title={t('REQUISITES')} sx={{ mb: 1 }}>
            <Stack spacing={2}>
              <TextField fullWidth name="title" label={t('TITLE')} />
              <TextField
                fullWidth
                name="companyCode"
                label={t('COMPANY_CODE')}
              />
              <TextField
                fullWidth
                name="companyVatCode"
                label={t('COMPANY_VAT_CODE')}
              />
              <TextField fullWidth name="address" label={t('ADDRESS')} />
              <TextField fullWidth name="city" label={t('CITY')} />
              <TextField fullWidth name="country" label={t('COUNTRY')} />
            </Stack>
          </Section>

          <Section title={t('AUTOMATIC_INVOICES')}>
            <SimpleChipInput
              name="invoiceEmailList"
              label={t('EMAIL_ADDRESSES')}
              variant="standard"
              sx={{ mt: 2 }}
            />
          </Section>

          <ActionBox>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={mutation.isLoading}
              startIcon={<SaveIcon />}>
              {t('SAVE')}
            </LoadingButton>
          </ActionBox>
        </Form>
      ) : (
        <BillingSettingsSkeleton />
      )}
    </>
  );
};

export default Settings;
