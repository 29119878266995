import React, { FC } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Usage } from 'features';

type Props = {
  usage: Usage;
};

const BillingUsageTableRow: FC<Props> = ({ usage }) => (
  <TableRow>
    <TableCell sx={{ textAlign: 'center' }}>{usage.date}</TableCell>
    <TableCell sx={{ textAlign: 'center' }}>{usage.telemetry}</TableCell>
    <TableCell sx={{ textAlign: 'center' }}>{usage.driverApp}</TableCell>
    <TableCell sx={{ textAlign: 'center' }}>{usage.accounting}</TableCell>
  </TableRow>
);

export default BillingUsageTableRow;
