import React, { FC } from 'react';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import Section from 'components/Section';

type Props = {
  cols: number;
  rows?: number;
};

const TableSkeleton: FC<Props> = ({ cols, rows = 5 }) => {
  return (
    <Section>
      <Table>
        <TableHead>
          <TableRow>
            {Array(cols)
              .fill(0)
              .map((_, i) => (
                <TableCell key={i}>
                  <Skeleton height={30} />
                </TableCell>
              ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {Array(rows)
            .fill(0)
            .map((_, i) => (
              <TableRow key={i}>
                {Array(cols)
                  .fill(0)
                  .map((_, i) => (
                    <TableCell key={i}>
                      <Skeleton />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Section>
  );
};

export default TableSkeleton;
