import { FC } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Breadcrumbs from 'components/Breadcrumbs';
import OrderSkeleton from 'components/OrderSkeleton';
import Section from 'components/Section';
import OrderInfoTable from 'components/OrderInfoTable';
import OrderHistoryEntry from 'components/OrderHistoryEntry';

import { useOrder } from 'features';

const Order: FC = () => {
  const { t } = useTranslation('Order');
  const { id } = useParams();
  const query = useOrder(id!);

  const order = query.data;

  if (order) {
    return (
      <>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'ORDERS', href: '/orders' },
            { label: `${t('ORDER')}: ${order.number}`, translate: false },
          ]}
        />

        {order.history.map((item, i) => (
          <OrderHistoryEntry key={i} entry={item} />
        ))}

        <Section sx={{ mt: 2 }}>
          <OrderInfoTable order={order} />

          {order.userComment && (
            <>
              <Typography variant="body1" sx={{ fontWeight: 600, mt: 3 }}>
                {t('ADDITIONAL_INFO')}
              </Typography>

              <Typography component="p">{order.userComment}</Typography>
            </>
          )}
        </Section>
      </>
    );
  }

  return <OrderSkeleton />;
};

export default Order;
