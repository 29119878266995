import React, { FC, ReactNode } from 'react';
import Card from '@mui/material/Card';

type Props = {
  children?: ReactNode;
};

const DashboardCard: FC<Props> = ({ children }) => (
  <Card sx={{ minWidth: 275 }}>{children}</Card>
);

export default DashboardCard;
