import { FC } from 'react';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumb, { BreadcrumbProps } from 'components/Breadcrumb';

type Props = {
  breadcrumbs?: BreadcrumbProps[];
};

const Breadcrumbs: FC<Props> = ({ breadcrumbs = [] }) => (
  <MuiBreadcrumbs
    separator={<NavigateNextIcon fontSize="small" />}
    sx={{ mb: 1 }}>
    <Breadcrumb label="HOME" href={breadcrumbs.length > 0 ? '/' : null} />

    {breadcrumbs.map((breadcrumb, i) => (
      <Breadcrumb key={i} {...breadcrumb} />
    ))}
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
