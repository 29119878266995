import { FC } from 'react';
import { useMutation } from 'react-query';

import { SESSION_COOKIE_DOMAIN } from 'const';
import { Login as LoginUI } from '@brunas/dashboard';

import { useErrorHandler, api } from 'lib';
import { AuthTokens } from 'features';

import logo from 'assets/img/bluebox-logo.png';

type Credentials = {
  email: string;
  password: string;
};

const Login: FC = () => {
  const onError = useErrorHandler();

  const mutation = useMutation(
    async (credentials: Credentials) =>
      await api('auth').post<AuthTokens>(
        '/auth/login',
        {
          ...credentials,
          login_type: 'email_password',
        },
        {
          cookie_domain: SESSION_COOKIE_DOMAIN!,
        }
      ),
    {
      onError,
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  return (
    <LoginUI
      logo={<img src={logo} alt="Brunas" />}
      loading={mutation.isLoading}
      onSubmit={mutation.mutate}
    />
  );
};

export default Login;
