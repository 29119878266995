import { FC } from 'react';

import Box from '@mui/material/Box';
import TrucksCard from 'components/DashboardTrucksCard';
import LatestInvoices from 'components/DashboardLatestInvoices';
import Breadcrumbs from 'components/Breadcrumbs';

const Dashboard: FC = () => (
  <>
    <Breadcrumbs />

    <Box sx={{ display: 'flex' }}>
      <Box>
        <TrucksCard />
      </Box>

      <Box sx={{ flexGrow: 1, pl: 2 }}>
        <LatestInvoices />
      </Box>
    </Box>
  </>
);

export default Dashboard;
