import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNotification } from '@brunas/dashboard';

import { api, useErrorHandler } from 'lib';
import { SESSION_COOKIE_DOMAIN } from 'const';
import {
  AuthClaims,
  PermissionGroup,
  UpdateProfileInput,
  User,
} from 'features';

export const authKeys = {
  all: [{ scope: 'auth' }] as const,
  me: () => [{ ...authKeys.all[0], entity: 'me' }] as const,
  permissions: () => [{ ...authKeys.all[0], entity: 'permissions' }] as const,
};

export const fetchMe = () => api('auth').get<AuthClaims>('/auth/me');

const fetchPermissions = () =>
  api('auth').get<PermissionGroup[]>('/permissions');

const updateMe = async (values: UpdateProfileInput) => {
  await api('auth').put<User>('/users/me', values);
  await api('auth').post(
    '/auth/refresh',
    {},
    { cookie_domain: SESSION_COOKIE_DOMAIN! }
  );
};

const changePassword = (values: UpdateProfileInput) =>
  api('auth').put<User>('/users/me', values);

export const useCurrentUser = () => {
  const query = useQuery(authKeys.me(), fetchMe, {
    refetchOnWindowFocus: false,
  });

  return query.data;
};

export const useUpdateCurrentUser = () => {
  const { t } = useTranslation('Profile');
  const { pop } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(updateMe, {
    onError: useErrorHandler(),
    onSuccess: () => {
      queryClient.invalidateQueries(authKeys.me());
      pop(t('SAVED'), 'success');
    },
  });
};

export const useUpdatePassword = () => {
  const { t } = useTranslation('Password');
  const { pop } = useNotification();
  const currentUser = useCurrentUser();

  const change = useCallback(
    (values: UpdateProfileInput) =>
      changePassword({ ...currentUser, ...values }),
    [currentUser]
  );

  return useMutation(change, {
    onError: useErrorHandler(),
    onSuccess: () => {
      pop(t('PASSWORD_CHANGED_SUCCESSFULLY'));
    },
  });
};

export const usePermissions = () =>
  useQuery(authKeys.permissions(), fetchPermissions);
