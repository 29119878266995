import { FC, useCallback } from 'react';

import SelectBase, { SelectBaseProps } from '@brunas/dashboard/dist/SelectBase';

import { useClients } from 'features';
import { SelectChangeEvent } from '@mui/material';
import { useSelectedClient } from 'components/SelectedClient/SelectedClient';

type Props = Omit<SelectBaseProps, 'options' | 'onChange' | 'value'>;

const ClientSelect: FC<Props> = props => {
  const query = useClients();
  const { client, setClient } = useSelectedClient();

  const options =
    query.data?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

  const handleChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      setClient(
        query.data?.find(item => item.id === event.target.value) ?? null
      );
    },
    [query.data, setClient]
  );

  return (
    <SelectBase
      options={options}
      value={client?.id ?? ''}
      onChange={handleChange}
      {...props}
    />
  );
};

export default ClientSelect;
