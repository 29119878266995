import React, { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import DashboardIcon from '@mui/icons-material/Dashboard';
import SellIcon from '@mui/icons-material/Sell';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { useLayout } from '@brunas/dashboard';
import { useSelectedClient } from 'components/SelectedClient';

const selected = (path: string, pathname: string) => pathname.startsWith(path);

const openIdent = 4;
const closedIdent = 2;

const MainMenu: FC = () => {
  const { pathname } = useLocation();
  const { mainMenuOpen } = useLayout();
  const { t } = useTranslation('MainMenu');
  const { client } = useSelectedClient();
  const [billingOpen, setBillingOpen] = useState(
    selected('/billing', pathname)
  );

  const handleBillingClick = useCallback(() => {
    setBillingOpen(c => !c);
  }, []);

  return (
    <List>
      <ListItemButton href="/" component={Link} selected={pathname === '/'}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>

        <ListItemText primary={t('DASHBOARD')} />
      </ListItemButton>

      <ListItemButton
        href="products"
        component={Link}
        selected={selected('/products', pathname)}>
        <ListItemIcon>
          <SellIcon />
        </ListItemIcon>

        <ListItemText primary={t('PRODUCTS')} />
      </ListItemButton>

      <ListItemButton
        href="orders"
        component={Link}
        selected={selected('/orders', pathname)}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>

        <ListItemText primary={t('ORDERS')} />
      </ListItemButton>

      <ListItemButton
        selected={selected('/billing', pathname)}
        onClick={handleBillingClick}>
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>

        <ListItemText primary={t('BILLING')} />
        {billingOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      <Collapse in={billingOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Divider />

          <ListItemButton
            href="billing/usage"
            selected={selected('/billing/usage', pathname)}
            component={Link}
            sx={{ pl: mainMenuOpen ? openIdent : closedIdent }}>
            <ListItemIcon>
              <DataUsageIcon />
            </ListItemIcon>

            <ListItemText primary={t('BILLING_USAGE')} />
          </ListItemButton>

          <ListItemButton
            href="billing/invoices"
            selected={selected('/billing/invoices', pathname)}
            component={Link}
            sx={{ pl: mainMenuOpen ? openIdent : closedIdent }}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>

            <ListItemText primary={t('BILLING_INVOICES')} />
          </ListItemButton>

          <ListItemButton
            href="billing/settings"
            selected={selected('/billing/settings', pathname)}
            component={Link}
            sx={{ pl: mainMenuOpen ? openIdent : closedIdent }}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>

            <ListItemText primary={t('BILLING_SETTINGS')} />
          </ListItemButton>

          <Divider />
        </List>
      </Collapse>

      <ListItemButton
        href="users"
        component={Link}
        selected={selected('/users', pathname)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>

        <ListItemText primary={t('USERS')} />
      </ListItemButton>

      <ListItemButton
        href={`https://${client?.domains[0]}/admin?module=setup&action=setup`}
        component={Link}
        selected={selected('/users', pathname)}>
        <ListItemIcon>
          <LocalShippingIcon />
        </ListItemIcon>

        <ListItemText primary={t('SYSTEM_SETUP')} />
      </ListItemButton>
    </List>
  );
};

export default MainMenu;
