import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import DashboardCard from 'components/DashboardCard';

import { useMonthlySummary } from 'features';

const DashboardTrucksCard: FC = () => {
  const { t } = useTranslation('DashboardTrucksCard');
  const query = useMonthlySummary();

  const { current, changePercents } = query.data ?? {};

  return (
    <DashboardCard>
      <CardContent>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{ fontSize: 14, flexGrow: 1 }}
            color="text.secondary"
            gutterBottom>
            {t('TRUCKS')}
          </Typography>

          <Typography
            sx={{
              fontSize: 14,
              borderRadius: 1,
              bgcolor: 'info.main',
              pl: 1,
              pr: 1,
            }}
            color="#ffffff"
            gutterBottom>
            {t('MONTHLY')}
          </Typography>
        </Box>

        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {query.isLoading ? <Skeleton width={75} /> : current}
        </Typography>

        <Typography color="text.secondary">
          {query.isLoading ? (
            <Skeleton />
          ) : (
            changePercents && (
              <>
                <Typography
                  component="span"
                  sx={{
                    color: changePercents > 0 ? 'success.main' : 'error.main',
                  }}>
                  {changePercents}%
                </Typography>{' '}
                {t('CHANGE_TEXT')}
              </>
            )
          )}
        </Typography>
      </CardContent>

      <CardActions>
        <Button size="small" href="/billing/usage">
          {t('VIEW_MORE')}
        </Button>
      </CardActions>
    </DashboardCard>
  );
};

export default DashboardTrucksCard;
