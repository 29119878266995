import React, { FC } from 'react';

import TableRow from '@mui/material/TableRow';

import { OrderItem } from 'features';
import TableCell from 'components/TableCell';

type Props = {
  item: OrderItem;
};

const OrderInfoTableRow: FC<Props> = ({ item }) => {
  const { title, amount, unitPrice, count } = item;

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{count}</TableCell>
      <TableCell>€{unitPrice}</TableCell>
      <TableCell>€{amount}</TableCell>
    </TableRow>
  );
};

export default OrderInfoTableRow;
