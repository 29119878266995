import { QueryFunctionContext, useQuery } from 'react-query';

import { api, useClientParams } from 'lib';
import { Invoice, InvoicesFetchParams } from 'features';

export const invoiceKeys = {
  all: [{ scope: 'invoices' }] as const,
  lists: () => [{ ...invoiceKeys.all[0], entity: 'list' }] as const,
  list: (params: InvoicesFetchParams) =>
    [{ ...invoiceKeys.lists()[0], params }] as const,
};

type InvoiceListContext = QueryFunctionContext<
  ReturnType<typeof invoiceKeys['list']>
>;

const fetchInvoices = ({ queryKey: [{ params }] }: InvoiceListContext) =>
  api().get<Invoice[] | null>('/invoices', params);

export const useInvoices = (limit?: number) => {
  const clientParams = useClientParams();

  return useQuery(
    invoiceKeys.list({
      ...clientParams,
      limit,
    }),
    fetchInvoices
  );
};
