import { FC, useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Section from 'components/Section';

import { Usage } from 'features';

type Props = {
  usage: Usage[];
};

type Counts = {
  date: string;
  count: number;
};

type Series = {
  label: string;
  data: Counts[];
};

const BillingUsageGraph: FC<Props> = ({ usage }) => {
  const { t } = useTranslation('BillingUsageGraph');
  const { palette } = useTheme();

  const graphData: Series[] = [
    {
      label: t('TELEMETRY'),
      data:
        usage.map(({ date, telemetry }) => ({
          date,
          count: telemetry,
        })) ?? [],
    },
    {
      label: t('DRIVER_APP'),
      data:
        usage.map(({ date, driverApp }) => ({
          date: date,
          count: driverApp,
        })) ?? [],
    },
    {
      label: t('ACCOUNTING'),
      data:
        usage.map(({ date, accounting }) => ({
          date: date,
          count: accounting,
        })) ?? [],
    },
  ];

  const primaryAxis = useMemo(
    (): AxisOptions<Counts> => ({
      getValue: datum => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<Counts>[] => [
      {
        getValue: datum => datum.count,
        elementType: 'line',
      },
    ],
    []
  );

  return (
    <Section sx={{ height: '420px' }}>
      <Chart
        options={{
          data: graphData,
          primaryAxis,
          secondaryAxes,
          padding: { bottom: 30, right: 30 },
          dark: palette.mode === 'dark',
        }}
      />
    </Section>
  );
};

export default BillingUsageGraph;
