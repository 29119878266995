import React, { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

type Props = {
  groups?: number;
  roles?: number;
};

const RolesInputSkeleton: FC<Props> = ({ groups = 2, roles = 3 }) => {
  return (
    <Table>
      <TableBody>
        {Array(groups)
          .fill(null)
          .map((_, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton height={32} />
              </TableCell>

              <TableCell>
                {Array(roles)
                  .fill(null)
                  .map((_, i) => (
                    <Box key={i}>
                      <Skeleton height={32} />
                    </Box>
                  ))}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default RolesInputSkeleton;
