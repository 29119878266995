import React, { FC, ReactNode } from 'react';
import MuiTableCell from '@mui/material/TableCell';

type Props = {
  children?: ReactNode;
};

const TableCell: FC<Props> = ({ children }) => (
  <MuiTableCell sx={{ textAlign: 'center' }}>{children}</MuiTableCell>
);

export default TableCell;
