import { useQuery } from 'react-query';

import { Client } from 'features';
import { api } from 'lib';

export const clientKeys = {
  all: [{ scope: 'clients' }] as const,

  lists: () => [{ ...clientKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...clientKeys.lists()[0] }] as const,
};

export const fetchClients = () => api('auth').get<Client[]>('/clients');

export const useClients = () => useQuery(clientKeys.list(), fetchClients);
