import { FC } from 'react';
import { Avatar as BaseAvatar } from '@brunas/dashboard';
import { useCurrentUser } from 'features';

const Avatar: FC = () => {
  const currentUser = useCurrentUser();

  return (
    <BaseAvatar
      firstName={currentUser?.firstName}
      lastName={currentUser?.lastName}
    />
  );
};

export default Avatar;
