import { FC } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import Skeleton from '@mui/material/Skeleton';
import Section from 'components/Section';

type Props = {
  sx?: SxProps<Theme>;
};

const OrderHistoryEntrySkeleton: FC<Props> = ({ sx }) => (
  <Section sx={[{ mb: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}>
    <Skeleton height={30} sx={{ width: '33%' }} />
    <Skeleton sx={{ width: '45%' }} />
    <Skeleton sx={{ width: '25%' }} />
  </Section>
);

export default OrderHistoryEntrySkeleton;
