import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import OrderInfoTableRow from 'components/OrderInfoTableRow';

import { Order } from 'features';

type Props = {
  order: Order;
};

const OrderInfoTable: FC<Props> = ({ order }) => {
  const { items, amount, amountVat, amountTotal } = order;
  const { t } = useTranslation('OrderInfoTable');

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: 'center' }}>{t('PRODUCT')}</TableCell>
          <TableCell sx={{ textAlign: 'center' }}>{t('AMOUNT')}</TableCell>

          <TableCell sx={{ textAlign: 'center' }}>
            {t('UNIT_PRICE_WITHOUT_VAT')}
          </TableCell>

          <TableCell sx={{ textAlign: 'center' }}>
            {t('TOTAL_WITHOUT_VAT')}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {items.map(item => (
          <OrderInfoTableRow item={item} key={item.title} />
        ))}

        <TableRow>
          <TableCell colSpan={3} sx={{ textAlign: 'right' }}>
            {t('TOTAL_WITHOUT_VAT')}
          </TableCell>

          <TableCell sx={{ textAlign: 'center' }}>€{amount}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={3} sx={{ textAlign: 'right' }}>
            {t('VAT')}
          </TableCell>

          <TableCell sx={{ textAlign: 'center' }}>€{amountVat}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={3} sx={{ textAlign: 'right' }}>
            {t('TOTAL')}
          </TableCell>

          <TableCell sx={{ textAlign: 'center' }}>€{amountTotal}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default OrderInfoTable;
