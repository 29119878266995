import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClientSelect from 'components/ClientSelect';
import TransportManagementButton from 'components/TransportManagementButton';

import { useIsSuper } from 'lib';

const MainToolbar: FC = () => {
  const { t } = useTranslation('MainToolbar');
  const isSuper = useIsSuper();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
        {t('APP_TITLE')}
      </Typography>

      {isSuper && (
        <Box sx={{ mr: 1 }}>
          <ClientSelect
            label={t('CLIENT')}
            sx={{
              width: '200px',
              '&.MuiSelect-select': {
                //color: 'red',
                backgroundColor: 'white',
              },
            }}
          />
        </Box>
      )}

      <Box sx={{ mr: 1 }}>
        <TransportManagementButton />
      </Box>
    </Box>
  );
};

export default MainToolbar;
