import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoadingButton from '@mui/lab/LoadingButton';
import ProductListSkeleton from 'components/ProductListSkeleton';
import ProductCard from 'components/ProductCard';
import ActionBox from 'components/ActionBox';

import { ProductsContext } from 'routes/Products';
import { useProducts } from 'features';

const ProductList: FC = () => {
  const { t } = useTranslation('ProductList');
  const { setActiveStep, hasSelectedProducts } = useContext(ProductsContext);

  const { data } = useProducts();

  const handlePlaceOrderClick = useCallback(() => {
    setActiveStep(1);
  }, [setActiveStep]);

  if (data) {
    return (
      <>
        {data.map((product, i) => (
          <ProductCard key={i} product={product} />
        ))}

        <ActionBox>
          <LoadingButton
            variant="contained"
            disabled={!hasSelectedProducts()}
            startIcon={<ShoppingCartIcon />}
            onClick={handlePlaceOrderClick}>
            {t('PLACE_ORDER')}
          </LoadingButton>
        </ActionBox>
      </>
    );
  }

  return <ProductListSkeleton />;
};

export default ProductList;
