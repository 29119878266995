import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import InvoicesTable from 'components/InvoicesTable';
import Section from 'components/Section';
import TableSkeleton from 'components/TableSkeleton';

import { useInvoices } from 'features';

const DashboardLatestInvoices: FC = () => {
  const { t } = useTranslation('DashboardLatestInvoices');
  const query = useInvoices(5);

  return (
    <Section
      title={t('INVOICES')}
      action={
        <Button size="small" href="/billing/invoices">
          {t('VIEW_ALL')}
        </Button>
      }>
      {query.data ? (
        query.data.length > 0 ? (
          <InvoicesTable invoices={query.data} />
        ) : (
          <Alert severity="info">{t('NO_INVOICES')}</Alert>
        )
      ) : (
        <TableSkeleton cols={4} />
      )}
    </Section>
  );
};

export default DashboardLatestInvoices;
