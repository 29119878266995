import React, { FC, ReactNode } from 'react';
import Paper from '@mui/material/Paper';

type Props = {
  children: ReactNode;
};

const ActionBox: FC<Props> = ({ children }) => (
  <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'row-reverse' }}>
    {children}
  </Paper>
);

export default ActionBox;
