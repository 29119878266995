import { useQuery } from 'react-query';

import { api } from 'lib';
import { Product } from 'features';

export const productKeys = {
  all: [{ scope: 'products' }] as const,
  lists: () => [{ ...productKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...productKeys.lists()[0] }] as const,
};

const fetchProducts = () => api().get<Product[]>('/devices');

export const useProducts = () => useQuery(productKeys.list(), fetchProducts);
