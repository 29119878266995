import { createContext, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ProductList from 'components/ProductList';
import ConfirmOrder from 'components/ConfirmOrder';
import Breadcrumbs from 'components/Breadcrumbs';
import { CreateOrderItem } from 'features';

type ActiveStep = 0 | 1 | 2;

type SelectedProducts = {
  [key: string]: number;
};

type ProductContextType = {
  activeStep: ActiveStep;
  selectedProducts: SelectedProducts;
  submitted: boolean;
  userComment?: string;
  getOrderItems: () => CreateOrderItem[];
  setSelectedProductAmount: (model: string, amount: number) => void;
  setUserComment: (comment: string) => void;
  setActiveStep: (step: ActiveStep) => void;
  hasSelectedProducts: () => boolean;
};

export const ProductsContext = createContext<ProductContextType>({
  activeStep: 0,
  selectedProducts: {},
  submitted: false,
  getOrderItems: () => [],
  setSelectedProductAmount: () => {},
  setUserComment: () => {},
  setActiveStep: () => {},
  hasSelectedProducts: () => false,
});

const Products: FC = () => {
  const [selectedProducts, setSelectdProducts] = useState<SelectedProducts>({});
  const [userComment, setUserComment] = useState('');
  const [activeStep, setActiveStep] = useState<ActiveStep>(0);
  const { t } = useTranslation('Products');
  const steps = [t('SELECT_EQUIPMENT'), t('CONFIRM_ORDER')];

  const setSelectedProductAmount = useCallback(
    (model: string, amount: number) => {
      setSelectdProducts(current => ({
        ...current,
        [model]: amount,
      }));
    },
    []
  );

  const hasSelectedProducts = useCallback(
    () =>
      Object.keys(selectedProducts)
        .map(model => selectedProducts[model])
        .reduce((sum, item) => sum + item, 0) > 0,
    [selectedProducts]
  );

  const getOrderItems = useCallback(
    () =>
      Object.keys(selectedProducts).map(device => ({
        device,
        count: selectedProducts[device],
      })),
    [selectedProducts]
  );

  const submitted = activeStep === 2;

  return (
    <ProductsContext.Provider
      value={{
        activeStep,
        selectedProducts,
        userComment,
        submitted,
        getOrderItems,
        setSelectedProductAmount,
        setUserComment,
        setActiveStep,
        hasSelectedProducts,
      }}>
      <Breadcrumbs breadcrumbs={[{ label: 'PRODUCTS' }]} />

      <Paper sx={{ p: 2, mb: 2 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={i}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>

      {activeStep === 0 ? <ProductList /> : <ConfirmOrder />}
    </ProductsContext.Provider>
  );
};

export default Products;
