import { FC, ReactNode } from 'react';
import { useQueries } from 'react-query';
import { AuthGuard, Splash } from '@brunas/dashboard';

import Login from 'components/Login';

import {
  AuthClaims,
  authKeys,
  clientKeys,
  fetchClients,
  fetchMe,
} from 'features';
import SelectedClient from 'components/SelectedClient';

type Props = {
  children: ReactNode;
};

const isLoggedIn = (data: AuthClaims | null | undefined) =>
  Boolean(data?.roles?.includes('admin') || data?.roles?.includes('super'));

const Auth: FC<Props> = ({ children }) => {
  const query = useQueries([
    {
      queryKey: authKeys.me(),
      queryFn: fetchMe,
      refetchOnWindowFocus: false,
      retry: false,
    },
    {
      queryKey: clientKeys.list(),
      queryFn: fetchClients,
      refetchOnWindowFocus: false,
      retry: false,
    },
  ]);

  if (query[0].isLoading || query[1].isLoading) return <Splash />;

  return (
    <AuthGuard loggedIn={isLoggedIn(query[0].data)} loginComponent={<Login />}>
      <SelectedClient>{children}</SelectedClient>
    </AuthGuard>
  );
};

export default Auth;
