import { QueryFunctionContext, useQuery } from 'react-query';

import {
  ClientParams,
  CreateOrderData,
  CreateOrderItem,
  CreateOrderRequest,
  Order,
  OrderPreviewParams,
} from 'features';
import { api, useClientParams } from 'lib';

export const orderKeys = {
  all: [{ scope: 'orders' }] as const,

  lists: () => [{ ...orderKeys.all[0], entity: 'list' }] as const,
  list: (params: ClientParams) =>
    [{ ...orderKeys.lists()[0], params }] as const,

  previews: () => [{ ...orderKeys.all[0], entity: 'preview' }] as const,
  preview: (data: CreateOrderData, params: OrderPreviewParams) =>
    [{ ...orderKeys.previews()[0], data, params }] as const,

  details: () => [{ ...orderKeys.all[0], entity: 'detail' }] as const,
  detail: (id: string, params: ClientParams) => [
    { ...orderKeys.details()[0], id, params },
  ],
};

type OrdersListContext = QueryFunctionContext<
  ReturnType<typeof orderKeys['list']>
>;

type OrdersDetailContext = QueryFunctionContext<
  ReturnType<typeof orderKeys['detail']>
>;

type OrderPreviewContext = QueryFunctionContext<
  ReturnType<typeof orderKeys['preview']>
>;

const fetchOrders = ({ queryKey: [{ params }] }: OrdersListContext) =>
  api().get<Order[]>('/orders', params);

const fetchOrder = ({ queryKey: [{ id, params }] }: OrdersDetailContext) =>
  api().get<Order>(`/orders/${id}`, params);

const fetchOrderPreview = ({
  queryKey: [{ data, params }],
}: OrderPreviewContext) => api().post<Order>('/orders', data, params);

export const createOrder = async ({ data, params }: CreateOrderRequest) =>
  await api().post<Order>('/orders', data, params);

export const useOrders = () =>
  useQuery(orderKeys.list(useClientParams()), fetchOrders);

export const useOrder = (id: string) =>
  useQuery(orderKeys.detail(id, useClientParams()), fetchOrder);

export const useOrderPreview = (items: CreateOrderItem[]) => {
  const clientParams = useClientParams();

  const params = {
    ...clientParams,
    preview: 1,
  } as const;

  return useQuery(orderKeys.preview({ items }, params), fetchOrderPreview);
};
