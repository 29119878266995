import { FC, ReactNode } from 'react';

import MuiTable from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

type Props = {
  header?: ReactNode[];
  children: ReactNode;
};

const Table: FC<Props> = ({ header = [], children }) => (
  <MuiTable>
    <TableHead>
      <TableRow>
        {header.map((item, i) => (
          <TableCell key={i} sx={{ textAlign: 'center' }}>
            {item}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

    <TableBody>{children}</TableBody>
  </MuiTable>
);

export default Table;
