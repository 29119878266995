import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, TextField } from '@brunas/dashboard';
import * as yup from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import GenderSelect from 'components/GenderSelect';

import { useCurrentUser, useUpdateCurrentUser, Gender } from 'features';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  gender: Gender | null;
};

const Profile: FC = () => {
  const { t } = useTranslation('Profile');
  const user = useCurrentUser();
  const mutation = useUpdateCurrentUser();

  const schema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .required(t('ENTER_EMAIL_ADDRESS'))
          .email(t('INCORRECT_EMAIL_FORMAT')),
        firstName: yup.string(),
        lastName: yup.string(),
        gender: yup.string().oneOf(['Male', 'Female'], t('INCORRECT_GENDER')),
      }),
    [t]
  );

  const initial = {
    email: user?.email ?? '',
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    gender: user?.gender ?? null,
  };

  const handleSubmit = useCallback(
    (values: FormValues) => {
      mutation.mutate({
        ...values,
        gender: values.gender ?? undefined,
      });
    },
    [mutation]
  );

  return (
    <Form<FormValues> initial={initial} onSubmit={handleSubmit} schema={schema}>
      <DialogContent>
        <Stack spacing={2}>
          <TextField name="email" label={t('EMAIL_ADDRESS')} />
          <TextField name="firstName" label={t('FIRST_NAME')} />
          <TextField name="lastName" label={t('LAST_NAME')} />
          <GenderSelect name="gender" label={t('GENDER')} />
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <LoadingButton
          type="submit"
          startIcon={<SaveIcon />}
          loading={mutation.isLoading}
          variant="contained">
          {t('SAVE')}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
};

export default Profile;
