import { ChangeEvent, FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { ProductsContext } from 'routes/Products';
import { Product } from 'features';

type Props = {
  product: Product;
};

const ProductCard: FC<Props> = ({ product }) => {
  const { model, description, price } = product;
  const { t } = useTranslation('ProductCard');
  const { selectedProducts, setSelectedProductAmount } =
    useContext(ProductsContext);

  const handleAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedProductAmount(model, Number(event.currentTarget.value));
    },
    [model, setSelectedProductAmount]
  );

  return (
    <Paper sx={{ mb: 1, p: 2, display: 'flex' }}>
      <Box sx={{ flexGrow: 1, mr: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {model}
        </Typography>

        <Typography variant="body2">{description}</Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <Box
          sx={{ width: '200px', display: 'flex', flexDirection: 'row', ml: 3 }}>
          <Box sx={{ width: '50%' }}>
            <TextField
              disabled
              variant="standard"
              value={price}
              label={t('PRICE')}
              sx={{ width: '50px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ width: '50%' }}>
            <TextField
              variant="standard"
              type="number"
              label={t('AMOUNT')}
              sx={{ width: '75px' }}
              value={selectedProducts[model] ?? 0}
              onChange={handleAmountChange}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ProductCard;
