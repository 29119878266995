import { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import RolesInputSkeleton from 'components/RolesInputSkeleton';

import { usePermissions } from 'features';

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

const RolesInputBase: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('RolesInputBase');
  const query = usePermissions();

  const handleChange = useCallback(
    (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        onChange([...value, key]);
      } else {
        onChange(value.filter((item: string) => item !== key));
      }
    },
    [onChange, value]
  );

  if (query.isLoading) return <RolesInputSkeleton />;

  return (
    <Box>
      <Typography sx={{ mt: 2 }}>{t('ASSIGN_ROLES')}</Typography>

      {!query.data ? (
        <Alert severity="warning" sx={{ mt: 1 }}>
          {t('NO_ROLES')}
        </Alert>
      ) : (
        <Table>
          <TableBody>
            {query.data?.map(({ key, title, permissions }) => (
              <TableRow key={key}>
                <TableCell>{title}</TableCell>

                <TableCell>
                  {permissions.map(({ title, key, description }) => (
                    <Box key={key}>
                      <FormControlLabel
                        label={title}
                        control={
                          <Checkbox
                            value={key}
                            checked={value.includes(key)}
                            onChange={handleChange(key)}
                          />
                        }
                      />

                      {description && (
                        <Tooltip title={description}>
                          <InfoIcon
                            fontSize="small"
                            sx={{
                              verticalAlign: 'middle',
                              display: 'inline-block',
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default RolesInputBase;
