import React, { FC } from 'react';

import TableSkeleton from 'components/TableSkeleton';
import OrderHistoryEntrySkeleton from 'components/OrderHistoryEntrySkeleton';
import Breadcrumbs from 'components/Breadcrumbs';

type Props = {};

const OrderSkeleton: FC<Props> = () => (
  <>
    <Breadcrumbs
      breadcrumbs={[{ label: 'ORDERS', href: '/orders' }, { skeleton: true }]}
    />

    <OrderHistoryEntrySkeleton />
    <OrderHistoryEntrySkeleton />
    <OrderHistoryEntrySkeleton sx={{ mb: 2 }} />

    <TableSkeleton cols={4} rows={3} />
  </>
);

export default OrderSkeleton;
